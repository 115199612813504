<template>
  <div>
    <h1>404，未找到该页面</h1>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>

<style scoped lang="less">
</style>